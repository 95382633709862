<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="权限名称"
          prop="name"
        >
          <el-input
            placeholder="权限名称"
            v-model.trim="formData.name"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="权限等级"
          prop="permissionLevel"
        >
          <el-select
            v-model="formData.permissionLevel"
          >
            <el-option
              v-for="(item, index) in filterDictionary('0106')"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="父级权限"
          prop="pid"
        >
          <el-select
            v-model="formData.pid"
          >
            <el-option
              v-for="(item, index) in menuList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="权限类型"
          prop="permissionType"
        >
          <el-select
            v-model="formData.permissionType"
          >
            <el-option
              v-for="(item, index) in filterDictionary('0105')"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="权限链接"
          prop="url"
        >
          <el-input
            placeholder="权限链接"
            v-model.trim="formData.url"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="权限标识"
          prop="sign"
        >
          <el-input
            placeholder="权限标识"
            v-model.trim="formData.sign"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="权限状态"
          prop="status"
        >
          <el-select
            v-model="formData.status"
          >
            <el-option
              v-for="(item, index) in filterDictionary('0104')"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import utils from '@/utils/utils';
import api from '@/api/AuthManageApi';

export default {
  name: 'AddAuthManage',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '新增权限',
      formData: {
        name: '',
        permissionLevel: '',
        pid: '',
        permissionType: '',
        url: '',
        sign: '',
        status: '',
      },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        permissionLevel: [{ required: true, message: '请选择', trigger: 'change' }],
        pid: [{ required: true, message: '请选择', trigger: 'change' }],
        permissionType: [{ required: true, message: '请选择', trigger: 'change' }],
        url: [{ required: true, message: '请输入', trigger: 'change' }],
        sign: [{ required: true, message: '请输入', trigger: 'change' }],
        status: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      menuList: [],
    };
  },
  watch: {},
  methods: {
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            id,
            name,
            permissionLevel,
            pid,
            permissionType,
            url,
            sign,
            status,
          } = this.formData;
          if (this.dialogTitle === '新增权限') {
            api.insertPermission(
              {
                name,
                permissionLevel,
                pid,
                permissionType,
                url,
                sign,
                status,
              },
            )
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            api.updatePermission(
              {
                id,
                name,
                permissionLevel,
                pid,
                permissionType,
                url,
                sign,
                status,
              },
            )
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
