import { postInfo } from './api';

export default {
  // 获取权限列表
  getPermissionList(data) {
    return postInfo('/permission/getPermissionList', data);
  },
  // 新增权限
  insertPermission(data) {
    return postInfo('/permission/insertPermission', data);
  },
  // 更新权限
  updatePermission(data) {
    return postInfo('/permission/updatePermission', data);
  },
  // 删除权限
  deletePermission(data) {
    return postInfo('/permission/deletePermission', data);
  },
};
